<template>
	<div class="library-wrap">
		<Header :navbg='true'></Header>
		<div class="section bigbg">
			<div class="">
				<div class="title">小院共享办公</div>
				<div class="subtitle">虚拟办公服务+基于社区图书馆的共享办公空间</div>
			</div>
			<div class="content">
				<div class="subtitle">
					线上线下，全链打通
				</div>
				<div class="subtitle">
					多地通用，随定随用
				</div>
				<div class="subtitle">
					基于社区图书馆的全方位服务
				</div>
			</div>
		</div>
		<div class=" van-swipe">
			<div class="title">一站式创业平台</div>
			<div class="intr">
				虚拟办公室（Virtual Office）是起源于欧美地区的一种办公室
				租用形式，其按需提供办公地址挂靠、行政文书处理、代接接
				电和商务接待等服务，租户可拥有黄金地段高级写字楼的商务
				地址，而不用在现场办公，方便树立租户的商业形象，并无需
				承担租金。创富港作为最早把商务中心模式引入中国地区的连
				锁企业，以自主研发并获国家软件认证的BITS商务智能电话系
				统为运作硬件基础，向租户们提供最完善的虚拟办公室服务。
			</div>
			<van-swipe :autoplay="3000"  indicator-color="white" class="banner" @change="change">
				<van-swipe-item v-for="(item,index) in serviceList" :key="index">
					<van-image :src="item.image" fit="cover" class="image"/>
				</van-swipe-item>
			</van-swipe>
			<div class="">
				<div class="swipe-name">{{serviceList[current].name}}</div>
				<div class="swipe-intr">{{serviceList[current].intr}}</div>
			</div>
		</div>
		<div class="van-swipe">
			<div class="title">空间类型</div>
			<div class="intr">从移动工位到会议室，小院生活打造可提高工作效率、创新和协作的办公环境。</div>
			<div class="typeItem" v-for="(typeItem,typeIndex) in typeList" :key="typeIndex">
				<img class="image" :src="typeItem.image">
				<div class="swipe-name">{{typeItem.name}}</div>
				<div class="swipe-intr">{{typeItem.intr}}</div>
				<div class="more">立即咨询></div>
			</div>
		</div>
		<div class="van-swipe">
			<div class="title">探索热门城市</div>
			<div class="intr">西安、渭南，6大办公地点中，随时随地选择您的理想办公空间</div>
			<van-swipe :autoplay="3000"  indicator-color="white" class="banner" @change="storeChange">
				<van-swipe-item v-for="(storeItem,storeIndex) in storeList" :key="storeIndex">
					<van-image :src="storeItem.image" fit="cover" class="image"/>
				</van-swipe-item>
			</van-swipe>
			<div class="swipe-name">{{storeList[storeCurrent].name}}</div>
		</div>
		<div class="facilities van-swipe">
			<div class="list">
				<div class="title">服务设施</div>
				<div class="intr">从精品咖啡到新鲜水果饮用水，从企业级打印机到健身空间， 我们的服务设施考虑您的多种办公需求</div>
				<div class="service-type">
					<div class="service-list">
						<div>·星巴克咖啡服务</div>
						<div>·水吧简餐</div>
						<div>·图书借阅</div>
						<div>·WIFI</div>
					</div>
					<div class="service-list">
						<div>·休息区</div>
						<div>·活动区</div>
						<div>·会议区</div>
						<div>·饮用水</div>
					</div>
					<div class="service-list">
						<div>·打印/复印机</div>
						<div>·储物柜</div>
						<div>·话筒音响</div>
						<div>·投影设备</div>
					</div>
					<div class="service-list">
						<div>·停车场</div>
						<div>·健身器材/健身房</div>
					</div>
				</div>
			</div>
			<div class="contact">
				<div class="invite">正在寻找一个灵活高端的办公空间？ 预约参观，我们将尽快与您确认。</div>
				<div class="btn">立即预约参观</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import Header from '@/components/head2022'
	import Footer from '@/components/foot2022'
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				serviceList:[
					{
						image: require("../../assets/images/new/shared1.png"),
						name:'企业托管服务',
						intr:'无需租用办公室，却可拥有专业企业形象。服务内容包含公司注册、变更等，轻松提升企业形象'
					},
					{
						image: require("../../assets/images/new/shared2.png"),
						name:'收件服务',
						intr:'无需支付工资，却拥有专属行政人员。专业行政人员提供信件、包裹收取以及行政服务'
					},
					{
						image: require("../../assets/images/new/shared3.png"),
						name:'秘书接电服务',
						intr:'无需支付工资，可拥有专属秘书接电服务，即使没有办公室，依然拥有专属秘书以公司名义接听电话'
					}
				],
				typeList:[
					{
						image: require("../../assets/images/new/shared4.png"),
						name:'随心座',
						intr:'针对个人客户、商旅人士推出的灵活办公解决方案。到店出示会员，即可随订随用，助您轻松工作，成就更多。',
						
					},
					{
						image: require("../../assets/images/new/shared5.png"),
						name:'移动工位',
						intr:'一张桌子，一个专属存储柜，一个移动矮柜，开启自己的工作生活，灵活空间选择。',
					},
					{
						image: require("../../assets/images/new/shared6.png"),
						name:'会议室预订',
						intr:'每个空间均有大小不等会议室为您提供，提前预约可直接使用，进行您的会议议程、培训讲座、合作商务会议举办等。',
					},
				],
				storeList: [
					{
						image: require("../../assets/images/new/shared7.png"),
						name: '泾渭五路社区馆',
				
					},
					{
						image: require("../../assets/images/new/shared8.png"),
						name: '渭南宏帆广场店',
					},
					{
						image: require("../../assets/images/new/shared9.png"),
						name: 'YUARD大雁塔帛薇店',
					},
					{
						image: require("../../assets/images/new/shared10.png"),
						name: '渭南 经开·未来城图书馆',
					},
				],
				current:0,	
				storeCurrent:0,
			
			}
		},
		methods: {
			change(e){
				this.current=e;
			},
			storeChange(e){
				this.storeCurrent=e;
			},
		}
	}
</script>

<style lang="scss">
	.library-wrap{
		.title{
			font-size: 26px;
			font-weight: bold;
			line-height:26px;
			text-align: center;
			margin-bottom: 35px;
			padding-top: 158px;
		}
		.subtitle{
			font-size: 16px;
			line-height: 16px;
			margin-bottom: 33px;
			
		}
		.intr{
			padding: 0 27px;
			font-size: 12px;
			line-height: 20px;
		}
		.btn {
			font-size: 12px;
			line-height: 32px;
			text-align: center;
			cursor: pointer;
			width:247px;
			height:32px;
			border: 1px solid #fff;
			border-radius:16px;
			margin: 0 auto;
		}
		.bigbg{
			background: url('../../assets/images/new/sharedbg.png') no-repeat;
			background-size: cover;
		}
		.section {
			box-sizing: border-box;
			width: 100%;
			height: 100vh;
			color: #FFFFFF;
			align-items: center;
			display: flex;
			flex-direction: column;
			.content{
				font-size: 16px;
				font-weight: bold;
				line-height: 16px;
				text-align: center;
				margin-top: 60px;
				.item{
					display: flex;
					flex-direction: column;
					cursor: pointer;
					margin-bottom: 47px;
				}
			}
		}
		.van-swipe{
			color:#000000 ;
			text-align: center;
			.intr{
				text-align: left;
				margin-bottom: 54px;
			}
			.typeItem{
				margin: 30px 0 40px;
				.swipe-intr{
					font-weight: bold;
					
				}
			}
			.image{
				width: 100%;
				height: 236px;
				border-radius: 3%;
			}
			.subtitle{
				margin:42px 0 33px;
				font-size:20px;
				font-weight: bold;
			}
			.swipe-name{
				font-size: 18px;
				line-height: 18px;
				font-weight: bold;
				margin: 13px 0 15px;
			}
			.swipe-intr{
				font-size: 12px;
				padding: 0 30px;
				line-height: 24px;
				font-weight: bold;
				
			}
			.more{
				 font-size: 10px;
				 line-height: 40px;
				 font-weight: 500;
			}
		}
		.banner {
			width: 77.34%;
			height: 192px;
			margin: 0 auto;
			border-radius: 5px;
			.image {
				width:100%;
			}
			:deep(.van-swipe__indicator) {
				width: 30px;
				height: 2px;
				border-radius: initial;
			}
		}
		.facilities{
			color: #000000;
			padding: 0 20px;
			margin-top: 28px;
				.title{
					padding-top: 0;
				}
				.intr{
					padding: 0;
					margin-bottom: 15px;
				}
				.list{
					.service-type{
						display: flex;
						justify-content: space-between;
						.service-list{
							text-align: left;
							margin-right: 5px;
							font-size: 10px;
							line-height: 20px;
							font-weight: bold;
						}
						
					}
				}
				.contact{
					.invite{
						font-size: 16px;
						font-weight: bold;
						line-height: 20px;
						margin:40px 0 40px;
						padding: 0 35px;
					}
					.btn{
						width: 247px;
						height: 32px;
						border: 2px solid #000000;
						border-radius: 16px;
						font-size: 12px;
						line-height:32px;
						text-align: center;
					}
				}
		}
	}
</style>